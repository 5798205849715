<template>
  <div class="sponsor py-5">
    <section class="sponsors" id="sponsors">
      <div class="heading text-light bg-primary py-3 text-center">
        <h1 class="gotham-font text-uppercase b-600 mb-0">
          SPONSORS AND EXHIBITORS
        </h1>
      </div>
      <div class="sub-heading text-center text-primary py-3">
        <h1 class="gotham-font text-uppercase b-600 mb-0">PLATINUM SPONSORS</h1>
      </div>
      <div class="container-fluid py-5">
        <div class="row py-3 justify-content-center align-items-center">
          <div
            class="col-sm-3 mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="http://www.abrasiveengineering.com.sg/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Platinum/abrasive engineering.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-3 mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://www.halliburton.com/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Platinum/hali-logo.png"
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="sub-heading text-center text-primary py-3">
        <h1 class="gotham-font text-uppercase b-600 mb-0">GOLD SPONSORS</h1>
      </div>

      <div class="container-fluid py-5">
        <div class="row py-3 justify-content-center align-items-center">
          <div
            class="col-sm-3 mb-2 d-flex justify-content-center align-items-center "
          >
            <a
              href="http://www.precisionlasersolutions.com/"
              target="_blank"
              class="text-decoration-none"
            >
              <img src="@/assets/img/sponsor/Gold/1.png" class="img-fluid" />
            </a>
          </div>
          <div
            class="col-sm-3 mb-2 d-flex justify-content-center align-items-center "
          >
            <a
              href="https://de.rosler.com/en-en/"
              target="_blank"
              class="text-decoration-none"
            >
              <img src="@/assets/img/sponsor/Gold/2.png" class="img-fluid" />
            </a>
          </div>
        </div>
      </div>

      <div class="sub-heading text-center text-primary py-3">
        <h1 class="gotham-font text-uppercase b-600 mb-0">SILVER SPONSORS</h1>
      </div>

      <div class="container-fluid py-5">
        <div class="row py-3 justify-content-center align-items-center">
          <div
            class="col-sm-3 mb-2 text-center"
          >
            <a
              href="https://www.micro-airtech.com.sg/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Silver/microair-2.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-3 mb-2"
          >
            <a
              href="https://tyrida-intl.com/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Silver/Tyrida.png"
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="exhibitors" id="exhibitors">
      <div class="heading text-primary py-3 text-center">
        <h1 class="gotham-font text-uppercase b-600 mb-0">EXHIBITORS</h1>
      </div>
      <div class="container-fluid py-5">
        <div class="row py-3 justify-content-center">
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://www.augmentus.tech/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/1.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://asplating.com"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/as-surface.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://www.anton-paar.com/sg-en/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/anton.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://www.100ion.com"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/CHUN-YUAN.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://creatz3d.com.sg/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/CREAT.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center"
          >
            <a
              href="https://www.frohn.com/en/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/2.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center mt-sm-3 mt-0"
          >
            <a
              href="https://www.mfn.li/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/3.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center mt-sm-3 mt-0"
          >
            <a
              href="https://www.mdpi.com/journal/coatings"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/MDPI.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center mt-sm-3 mt-0"
          >
            <a
              href="https://www.oxinst.com"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/oxford.png"
                class="img-fluid"
              />
            </a>
          </div>
        
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center mt-sm-3 mt-0"
          >
            <a
              href="https://www.pulstec.net/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/pulstec.png"
                class="img-fluid"
              />
            </a>
          </div>
          <div
            class="col-sm-2 col-6  mb-2 d-flex justify-content-center align-items-center mt-sm-3 mt-0"
          >
            <a
              href="https://shockform.com/"
              target="_blank"
              class="text-decoration-none"
            >
              <img
                src="@/assets/img/sponsor/Exhibitor/shockform.png"
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="package mb-5" id="package">
      <div class="heading text-primary py-3 text-center">
        <h1 class="gotham-font text-uppercase b-600 mb-0">
          SPONSORS AND EXHIBITORS PACKAGE
        </h1>
      </div>
      <div class="container-fluid text-center">
        <img
          src="@/assets/img/sponsor/Packages.png"
          class="img-fluid"
          alt="SPONSORS AND EXHIBITORS PACKAGE"
        />
        <div class="text-center py-5">
          <p class="mb-0">
            Interested to take part as a sponsor or exhibitor to
          </p>
          <p>showcase your products and services?</p>
          <a
            class="btn btn-warning text-primary fw-bold fs-4 px-5"
            href="mailto:info@incase2023.org"
            target="_blank"
          >
            CONTACT US NOW AT
            <br />
            INFO@INCASE2023.ORG
          </a>
        </div>
      </div>
    </section>

    <contact />
    <footerComp />
  </div>
</template>

<script>
import footerComp from "@/components/layouts/footer.vue";
import contact from "@/components/layouts/contactus.vue";
export default {
  name: "sponsor",
  components: {
    footerComp,
    contact,
  },
  data() {
    return {};
  },
  mounted() {
    this.globalDeviceCheck();
  },
};
</script>
<style lang="scss" scoped>
.sponsor {
  min-height: 100vh;
  .gotham-font {
    font-family: "GothamRounded", sans-serif;
  }
  .opensans-font {
    font-family: "Open Sans", sans-serif;
  }
  .alt-row {
    background: rgb(247, 247, 247);
  }
  .nav-desktop-bg {
    background: #5a89e3;
  }
  .nav-tabs {
    border-bottom: none;

    overflow: hidden;
    .nav-link {
      border: 1px solid #5a89e3;

      color: #fff;
      background-color: #5a89e3;
      font-weight: 700;
      padding: 0.5rem 2.8rem;
      &.active {
        border: none;
        color: #f5e868;
        background: #1f3485;
      }
    }
  }
}
</style>
